/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import store from "store";
import Swal from "sweetalert2";
import {
  Timestamp,
  addDoc,
  collection,
  deleteField,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useIntl } from "react-intl";

// Constants
import {
  API_ERRORS_COLLECTION,
  PLATFORM_SERVICES_COLLECTION,
  USER_DATA_AND_CHATBOT_COLLECTION,
} from "constants/global/firebase-collections";
import { Analytics } from "config/firebase";
import { logEvent, setCurrentScreen } from "firebase/analytics";

// Functions
import { getFbAccountPermissions } from "./facebookAds";
import { getTiktokAdvertiserDetail } from "./tiktokAds";
import { getGoogleAccountIssues } from "./googleAds";
import { FACEBOOK_PERMISSIONS } from "constants/facebook";
import { ApiServerPath } from "api_server";

export const generateKey = (): string => {
  return Math.round(Math.random() * 1000000).toLocaleString();
};

export const sweetAlert = (
  text?: any,
  icon?: any,
  imageUrl?: any,
  title?: any,
  link?: string,
  linkText?: string
) => {
  Swal.fire({
    title: title || (icon === "error" ? "Oops..." : ""),
    text: text ? text : null,
    imageUrl: imageUrl
      ? imageUrl
      : icon === "error"
      ? "images/global/x-mark.png"
      : "images/global/info.png",
    imageHeight: 50,
    confirmButtonColor: "var(--text-grey)",
    customClass: {
      container: "my-swal",
    },
    html: link && `${text} \n <a href=${link} target='_blank'>${linkText}</a>`,
  });
};

export const actionAlert = async (
  text?: any,
  confirm?: any,
  confirmButtonText?: any,
  cancelButtonText?: any,
  isShowCancelBtn?: boolean,
  link?: string,
  linkText?: string,
  icon?: string
) => {
  const result: any = await Swal.fire({
    text,
    showCancelButton: isShowCancelBtn,
    confirmButtonText: confirmButtonText,
    denyButtonText: cancelButtonText,
    confirmButtonColor: "var(--text-grey)",
    imageUrl:
      icon === "error" ? "images/global/x-mark.png" : "images/global/info.png",
    imageHeight: 50,
    html: link && `${text} \n <a href=${link} target='_blank'>${linkText}</a>`,
  });

  if (result.isConfirmed) {
    confirm();
  }
};

export const isOnline = () => window.navigator.onLine || true;

export const WriteConnectionErrorToFirebase = async (
  apiResponse: any,
  type: any
) => {
  const { userData = {} }: any = store.getState().appReducer;
  const { lastSelectedWorkspace = {} } = userData;
  const { workspaceId } = lastSelectedWorkspace;

  const ref = doc(API_ERRORS_COLLECTION, workspaceId);
  const collectionRef = collection(ref, "connectionApiError");
  await addDoc(collectionRef, {
    apiResponse,
    uid: workspaceId,
    type,
    createdAt: Timestamp.now(),
    device: "DESKTOP",
  });
};

export const updatePlatformServices = (key: any, value: any) => {
  try {
    const { userData = {} }: any = store.getState().appReducer;
    const { lastSelectedWorkspace = {} } = userData;
    const { workspaceId } = lastSelectedWorkspace;
    updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), { [key]: value });
  } catch (error) {
    console.log(error);
  }
};

export const updateUserDataAndChatbot = async (key: any, value: any) => {
  const { userData = {} }: any = store.getState().appReducer;
  const { lastSelectedWorkspace = {} } = userData;
  const { workspaceId } = lastSelectedWorkspace;

  updateDoc(doc(USER_DATA_AND_CHATBOT_COLLECTION, workspaceId), {
    [key]: value,
  });
};

export const updatePlatformService = (
  idKey?: any,
  nameKey?: any,
  item?: any,
  currencyKey?: any,
  tiktokCurrencyCode?: any,
  type?: string
) => {
  let {
    id,
    name,
    currency,
    customerId,
    currencyCode,
    advertiser_id,
    advertiser_name,
    managerId,
    reference,
    account_id,
    currency_code,
    customerid: bingCustomerId,
  } = item;

  name = name || advertiser_name;
  id = id || customerId || advertiser_id || account_id;
  currencyCode =
    tiktokCurrencyCode || currency || currencyCode || currency_code;

  const { userData = {} }: any = store.getState().appReducer;
  const { lastSelectedWorkspace = {} } = userData;
  const { workspaceId } = lastSelectedWorkspace;

  updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
    [idKey]: id,
    [nameKey]: name,
    [currencyKey]: currencyCode,
  });

  if (type === "GOOGLE") {
    if (managerId) {
      updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
        "googleAdwords.managerId": managerId,
        "googleAdwords.isChild": true,
      });
    } else {
      updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
        "googleAdwords.isChild": false,
        "googleAdwords.managerId": null,
      });
    }
  }

  if (type === "LINKEDIN") {
    updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
      "linkedinAds.organizationId": reference,
    });
  }

  if (type === "BING_ADS") {
    updateDoc(doc(PLATFORM_SERVICES_COLLECTION, workspaceId), {
      "bingAds.customerId": bingCustomerId,
    });
  }

  return false;
};

export const sendAccessInvitationForGoogleAds = async (
  customerId: any,
  refreshToken: any,
  managerId: any
) => {
  try {
    await axios.post(
      `${ApiServerPath.BASE_URL}${ApiServerPath.SEND_ACCESS_INVITATION}`,
      {
        customerId,
        refreshToken,
        managerId: managerId || null,
      }
    );
  } catch (error) {
    console.log(error, "error in google access");
  }
};

export const _onSelectAdsItem = async (
  item: any,
  idKey: any,
  idName: any,
  currencyKey: any,
  type: any,
  workspaceId: any,
  fromCreateIdFlow?: boolean
  // googleAdwords: any
) => {
  const { customerId, isManager, managerId } = item;

  try {
    if (type === "GOOGLE") {
      if (!isManager) {
        // const { refreshToken } = googleAdwords;

        const accIssue = await getGoogleAccountIssues(customerId, managerId);

        if (accIssue && customerId !== 8211450874) {
          sweetAlert(accIssue);
        } else {
          // await sendAccessInvitationForGoogleAds(
          //     customerId,
          //     refreshToken,
          //     managerId
          // );
          const docRef = doc(USER_DATA_AND_CHATBOT_COLLECTION, workspaceId);
          updateDoc(docRef, {
            googleObjective: deleteField(),
            googleConversionGoal: deleteField(),
          });

          return updatePlatformService(
            idKey,
            idName,
            item,
            currencyKey,
            null,
            type
          );
        }
      } else {
        sweetAlert("You can not select manager account.");
      }
    } else {
      if (type === "TIKTOK") {
        const tiktokCurrencyCode = await getTiktokAdvertiserDetail(
          item?.advertiser_id
        );

        const docRef = doc(USER_DATA_AND_CHATBOT_COLLECTION, workspaceId);
        updateDoc(docRef, {
          tiktokConversionGoal: deleteField(),
          tiktokPixelId: deleteField(),
        });

        return updatePlatformService(
          idKey,
          idName,
          item,
          currencyKey,
          tiktokCurrencyCode
        );
      } else if (type === "LINKEDIN") {
        if (
          item?.reference &&
          item?.reference?.indexOf("organization") !== -1
        ) {
          return updatePlatformService(
            idKey,
            idName,
            item,
            currencyKey,
            null,
            type
          );
        } else {
          sweetAlert(
            `LinkedIn ad accounts needs to be linked with company profile.`,
            null,
            null,
            null,
            "https://www.linkedin.com/help/lms/answer/a427419",
            "https://www.linkedin.com/help/lms/answer/a427419"
          );
        }
      } else if (type === "BING_ADS") {
        return updatePlatformService(
          idKey,
          idName,
          item,
          currencyKey,
          null,
          type
        );
      } else if (type === "FACEBOOK") {
        const permissions: any = await getFbAccountPermissions(
          fromCreateIdFlow
        );

        console.log(permissions, "prermissionsss");
        if (permissions?.permissions?.data?.length) {
          const isNotAllPermissions = permissions?.permissions?.data
            ?.filter((item: { permission: string; status: string }) =>
              (fromCreateIdFlow
                ? ["business_management"]
                : FACEBOOK_PERMISSIONS
              ).includes(item.permission)
            )
            ?.find(
              (item: { permission: string; status: string }) =>
                item.status !== "granted"
            );

          console.log(isNotAllPermissions, "isNotAllPermissions");

          if (isNotAllPermissions) {
            sweetAlert(
              fromCreateIdFlow
                ? "We kindly request you to review and allow business management permission of your account."
                : "We kindly request you to review and allow all the permissions of your account. Granting all permissions is required to establish a complete connection of account and providing full functionality of our services."
            );
          } else {
            const isMangerPermission =
              item?.user_tasks && item?.user_tasks.includes("MANAGE");
            const isAdvertiserPermission =
              item?.user_tasks && item?.user_tasks.includes("ADVERTISE");
            if (item?.account_status === 2) {
              sweetAlert(
                `To help keep Facebook safe and ensure positive experiences between people and businesses, Facebook reviews ad accounts to check for violations of their Advertising Policies and Terms of Service. This may result in ad accounts that don't comply with these policies and terms being disabled.\n You can check the status of your ad accounts in \n https://www.facebook.com/accountquality,\n which you can access when signed in to your Facebook account.`,
                null,
                null,
                "Your Ad account is Disabled"
              );
            } else if (item?.account_status === 3) {
              sweetAlert(
                "The Facebook ad account has an outstanding unpaid balance on it and needs to be paid before more ads will be delivered.",
                null,
                null,
                "Your Ad account is Unsettled"
              );
            } else if (item?.account_status === 101) {
              sweetAlert(
                `When your ad account is closed, your primary payment method is removed, your ads are turned off and the closed account won't be able to publish new ads. To create new ads with this ad account, you need to make it active. Deactivating an ad account doesn't make room for new ad accounts.\n Note: A disabled ad account is not the same as a closed ad account. A disabled ad account can't be made active. You must appeal to Facebook to reactivate a disabled ad account.`,
                null,
                null,
                "Your Ad account is Closed"
              );
            } else if (
              !fromCreateIdFlow &&
              item?.user_tasks &&
              isMangerPermission &&
              !item?.funding_source &&
              item?.id !== "act_115263056585426" &&
              item?.id !== "act_427053459589047"
            ) {
              sweetAlert(
                `Add a valid payment method to your ad account.\n https://www.facebook.com/business/help/132073386867900?id=160022731342707`,
                null,
                null,
                "No Payment Method"
              );
            } else if (
              !fromCreateIdFlow &&
              item?.user_tasks &&
              !isAdvertiserPermission &&
              item?.id !== "act_115263056585426" &&
              item?.id !== "act_427053459589047"
            ) {
              sweetAlert(
                `You don't have enough permissions to advertise. Please ask your account manger for permissions to advertise.`,
                null,
                null,
                "Access Required"
              );
            } else {
              if (fromCreateIdFlow) {
                let { id, name, currency, currencyCode, currency_code } = item;
                currencyCode = currency || currencyCode || currency_code;
                return {
                  accountId: id,
                  accountName: name,
                  currencyCode,
                };
              } else {
                return updatePlatformService(idKey, idName, item, currencyKey);
              }
            }
          }
        } else {
          sweetAlert(permissions?.error?.message);
          if (fromCreateIdFlow) {
            return false;
          }
        }
      } else {
        return updatePlatformService(idKey, idName, item, currencyKey);
      }
    }
  } catch (error) {
    sweetAlert(error);
  }
};

export const addPlatformService = async (
  platformService: any,
  refreshToken: any,
  id: any,
  accessToken?: string,
  isNewApp?: boolean
) => {
  try {
    const ref = doc(PLATFORM_SERVICES_COLLECTION, id);
    const getData = await getDoc(ref);
    const data = getData.data();

    if (data !== undefined) {
      await updateDoc(ref, {
        [`${platformService}.isConnected`]: true,
        [`${platformService}.selectedOption`]: "30",
        [`${platformService}.refreshToken`]: refreshToken,
        [`${platformService}.platformServiceName`]: platformService,
        [`${platformService}.viewId`]: deleteField(),
        [`${platformService}.viewName`]: deleteField(),
        [`${platformService}.accountId`]: deleteField(),
        [`${platformService}.propertyId`]: deleteField(),
        [`${platformService}.accountName`]: deleteField(),
        [`${platformService}.propertyName`]: deleteField(),
      });
      if (platformService === "linkedinAds") {
        await updateDoc(ref, {
          [`${platformService}.accessToken`]: accessToken,
        });
      }

      if (platformService === "snapchatAds") {
        await updateDoc(ref, {
          [`${platformService}.isNewApp`]: isNewApp,
        });
      }
    } else {
      if (platformService === "linkedinAds") {
        await setDoc(ref, {
          [platformService]: {
            refreshToken,
            isConnected: true,
            selectedOption: "30",
            platformServiceName: platformService,
            accessToken,
          },
        });
      } else if (platformService === "snapchatAds") {
        await setDoc(ref, {
          [platformService]: {
            refreshToken,
            isConnected: true,
            selectedOption: "30",
            platformServiceName: platformService,
            isNewApp,
          },
        });
      } else {
        await setDoc(ref, {
          [platformService]: {
            refreshToken,
            isConnected: true,
            selectedOption: "30",
            platformServiceName: platformService,
          },
        });
      }
    }
  } catch (error) {
    WriteConnectionErrorToFirebase(error, platformService);
  }
};

export const FIREBASE_EVENTS = (eventName: any, screenName: any) => {
  logEvent(Analytics, eventName);
  setCurrentScreen(Analytics, screenName);
};

export const _UpdatePlatformServices = (uid: string, payload: any) => {
  try {
    axios.post(
      `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.UPDATE_PLATFORM_SERVICES}`,
      {
        payload,
        uid,
      }
    );
  } catch (error) {
    console.log(error, "platform update error");
  }
};

export const formatMessageInPlaceholder = (text: string) => {
  return useIntl().formatMessage({
    id: text || "Example",
    defaultMessage: text,
  });
};
