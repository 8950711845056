import { useEffect, useState } from "react";
import AccountConnection from "pages/AccountConnection";
import { facebookGraphInit } from "functions/facebookAds";
import { getUserData, setWorkspaceId } from "actions/app";
import store from "store";
import { Oval } from "react-loader-spinner";
import { getSigninToken } from "functions/login";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "config/firebase";
import FbManagerAccess from "pages/FbManagerAccess";
import GoogleLinkBridge from "pages/GoogleLinkBridge";

const ConnectPlatforms = () => {
  const [params, setParams] = useState();
  const [connectId, setConnectId] = useState("");
  const [googleConnectId, setGoogleConnectId] = useState("");
  const [isGettingStarted, setIsGettingStarted] = useState(false);

  const handleSigninWithToken = async (locationId: string) => {
    try {
      setIsGettingStarted(true);
      const token: any = await getSigninToken(locationId);
      if (token) {
        await signInWithCustomToken(auth, token);
        store.dispatch(getUserData(locationId));
        store.dispatch(setWorkspaceId(locationId));
      }
    } catch (error) {
      const errorMessage = (error as any).message;
      console.log(errorMessage, "error message");
    } finally {
      setIsGettingStarted(false);
    }
  };

  useEffect(() => {
    if (window.location.search) {
      const url = new URL(window.location.href);
      console.log(url, "url string");
      const params: any = new URLSearchParams(url.search);
      const state = JSON?.parse(params?.get("state"));
      const id: any = params?.get("id") || state?.id;
      const connect_id: any = params?.get("connect_id") || state?.connect_id;
      const google_connect_id: any = params?.get("google_connect_id") || state?.google_connect_id;
      if (id) {
        if (!auth?.currentUser?.uid || auth?.currentUser?.uid !== id) {
          handleSigninWithToken(id);
        } else {
          store.dispatch(getUserData(id));
          store.dispatch(setWorkspaceId(id));
        }

        setParams(params);
        facebookGraphInit();
      } else if (connect_id) {
        console.log(connect_id, "asdjkasdhakjsdhajksh");
        setConnectId(connect_id);
        facebookGraphInit();  
      } else if (google_connect_id) {
        console.log(connect_id, "asdjkasdhakjsdhajksh");
        setGoogleConnectId(google_connect_id);
        // facebookGraphInit();
      }
    }
  }, []);

  return connectId ? (
    <FbManagerAccess connectId={connectId} />
  ) : googleConnectId ? <GoogleLinkBridge connectId={googleConnectId}/> : isGettingStarted || !params ? (
    <div>
      <Oval
        height={40}
        width={40}
        color={"var(--color-primary)"}
        wrapperStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="var(--text-grey)"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  ) : (
    <AccountConnection params={params} />
  );
};

export default ConnectPlatforms;
