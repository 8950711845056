/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React from "react";
import "./styles.css";
import { Oval } from "react-loader-spinner";
import AppText from "components/AppText";

const AppButton = (props: any) => {
  const { title, className, loading, loaderColor } = props;

  return (
    <button
      {...props}
      className={`launch-tab-button-review ${className}`}
      disabled={loading}
    >
      {loading ? (
        <div className="app-btn-loader-container">
          <Oval
            height={25}
            width={25}
            color={loaderColor || "white"}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <AppText>{title}</AppText>
      )}
    </button>
  );
};

export default AppButton;
