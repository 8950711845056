/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { ReactNode, HTMLProps, ReactElement } from "react";
import { FormattedMessage } from "react-intl";

interface AppTextProps extends HTMLProps<HTMLParagraphElement> {
  children: ReactNode;
  values?: any;
  className?: string; // Add className prop
  onClick?: any;
  style?: any;
}

const isReactElement = (child: any): child is ReactElement => {
  return typeof child === "object" && "type" in child && "props" in child;
};

const AppText: React.FC<AppTextProps> = ({
  values,
  children,
  className,
  onClick,
  style,
}) => {
  const processChildren = (child: ReactNode): ReactNode => {
    if (typeof child === "string") {
      return (
        <FormattedMessage
          defaultMessage={child}
          id={child || 'Processing...'} // Use the child content as the ID
          values={values}
        />
      );
    } else if (Array.isArray(child)) {
      return child.map((c, index) => (
        <React.Fragment key={index}>{processChildren(c)}</React.Fragment>
      ));
    } else if (isReactElement(child)) {
      const { children: childChildren, ...props } = child.props;
      return React.cloneElement(
        child,
        props,
        Array.isArray(childChildren)
          ? childChildren.map((c, index) => (
            <React.Fragment key={index}>{processChildren(c)}</React.Fragment>
          ))
          : processChildren(childChildren)
      );
    }
    return child;
  };

  // Apply className to the wrapper element
  return (
    <span style={style} onClick={onClick} className={className}>
      {React.Children.map(children, processChildren)}
    </span>
  );
};

export default AppText;
