export const FACEBOOK_PERMISSIONS = [
  "email",
  "read_insights",
  "pages_show_list",
  "ads_management",
  "leads_retrieval",
  "pages_read_engagement",
  "pages_manage_metadata",
  "pages_read_user_content",
  "pages_manage_ads",
  "public_profile",
  "business_management",
];
